import React, { useRef, useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Keyboard, Mousewheel } from "swiper";
// Import Swiper styles | Production |
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ModalContext from "../ModalContext";
import Nav from "../components/Layout/Nav";
import IleFoxes from "../Templates/Islands/IleFoxes";
import IleAvantLeSpectacle from "../Templates/Islands/IleAvantLeSpectacle.jsx";
import IleCourriers from "../Templates/Islands/IleCourriers";
import IleTadam from "../Templates/Islands/IleTadam";
import IleGrouLeJeu from "../Templates/Islands/IleGrouLeJeu";
import IleTheatreJeunePublic from "../Templates/Islands/IleTheatreJeunePublic";

import ModalFoxesChoices from "../Templates/ModalTemplates/ModalFoxesChoices";
import ModalAvantLeSpectacle from "../Templates/ModalTemplates/ModalAvantLeSpectacle";
import ModalCourrier from "../Templates/ModalTemplates/ModalCourrier";
import ModalJeunePublic from "../Templates/ModalTemplates/ModalJeunePublic";
import ModalGrouLeJeu from "../Templates/ModalTemplates/ModalGrouLeJeu";
import ModalTadam from "../Templates/ModalTemplates/ModalTadam";
import "./HomeIslands.scss";

import Lottie from "lottie-react";
import ModalCredits from "../Templates/ModalTemplates/ModalCredits";
import Coin from "../assets/lottie/coin_animation_lltj4ron.json";

import prevNextArrow from "../assets/img/elements/arrow_selector.svg";

import clouds from "../assets/img/intro/clouds.webp";

export default function HomeIslands() {
	const { modalContent, toggleModal } = useContext(ModalContext);

	const prevRef = useRef(null);
	const nextRef = useRef(null);
	const swiperRef = useRef(null);

	function checkActiveIndex(activeIndex) {
		nextRef.current.classList.remove("disabled");
		prevRef.current.classList.remove("disabled");
		if (activeIndex === 0) {
			prevRef.current.classList.add("disabled");
			return;
		}
		if (activeIndex === 5) {
			nextRef.current.classList.add("disabled");
			return;
		}
	}

	useEffect(() => {
		toggleModal(false);
		return () => {
			toggleModal(false);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		const updateSwiper = () => {
			if (swiperRef.current) {
				swiperRef.current.update(); // Forcer le recalcul des tailles et positions
			}
		};

		// Appeler la fonction immédiatement après le montage
		updateSwiper();

		// Si besoin, ajouter un léger délai pour s'assurer que les styles sont bien appliqués
		const timeout = setTimeout(updateSwiper, 100);

		return () => clearTimeout(timeout);
	}, []);

	return (
		<>
			<main className='page_container sky'>
				{/* <div className='marker'></div> */}
				<Lottie
					className='coinCredit'
					animationData={Coin}
					loop
					autoplay
					onClick={() => toggleModal(<ModalCredits />)}
				/>
				{modalContent}
				<Nav />
				<img className='clouds_1' src={clouds} alt='' width={704} height={462} />
				<img className='clouds_2' src={clouds} alt='' width={704} height={462} />
				<button ref={prevRef} className='swiper-cta-prev'>
					<img src={prevNextArrow} alt='' />
				</button>
				<button ref={nextRef} className='swiper-cta-next'>
					<img src={prevNextArrow} alt='' />
				</button>
				<div className='islands_container '>
					<Swiper
						modules={[Navigation, Mousewheel, Pagination, Keyboard]}
						slidesPerView={"auto"}
						centeredSlides={true}
						mousewheel={true}
						observer={true}
						draggable={true}
						keyboard={{
							enabled: true,
						}}
						// navigation
						navigation={{
							prevEl: ".swiper-cta-prev",
							nextEl: ".swiper-cta-next",
						}}
						pagination={{
							clickable: true,
							dynamicBullets: true,
						}}
						speed='500'
						onSwiper={(swiper) => {
							if (swiperRef.current) {
								swiperRef.current.update();
							}
							swiperRef.current = swiper;
							checkActiveIndex(swiper.activeIndex);
						}}
						onSlideChange={(swiper) => checkActiveIndex(swiper.activeIndex)}>
						<SwiperSlide onClick={() => toggleModal(<ModalAvantLeSpectacle />)}>
							<IleAvantLeSpectacle />
						</SwiperSlide>
						<SwiperSlide
							className='smallones'
							onClick={() => toggleModal(<ModalCourrier />)}>
							<IleCourriers />
						</SwiperSlide>
						<SwiperSlide
							onClick={() =>
								toggleModal(
									<ModalFoxesChoices toggleModal={toggleModal} />
								)
							}>
							<IleFoxes />
						</SwiperSlide>
						<SwiperSlide
							onClick={() =>
								toggleModal(
									<ModalGrouLeJeu toggleModal={toggleModal} />
								)
							}>
							<IleGrouLeJeu />
						</SwiperSlide>

						<SwiperSlide
							onClick={() =>
								toggleModal(<ModalTadam toggleModal={toggleModal} />)
							}>
							<IleTadam />
						</SwiperSlide>
						<SwiperSlide
							onClick={() =>
								toggleModal(
									<ModalJeunePublic toggleModal={toggleModal} />
								)
							}>
							<IleTheatreJeunePublic />
						</SwiperSlide>
					</Swiper>
				</div>
			</main>
		</>
	);
}
