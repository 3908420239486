import React from "react";
import { Link } from "react-router-dom";
import "./ModalFoxesDommage.scss";

export default function ModalTadamDommage() {
	return (
		<div id='modal-foxes-dommage' className='modal__wrapper modal__wrapper--blue'>
			<h4>Dommage…</h4>
			<div className='text'>
				<h5>Vous risquez de vous divulgâcher quelques surprises...</h5>
				<div className='redirect_user_content'>
					<div className='agenda'>
						<p>Il serait préférable de <span className="oliver">choisir une date </span> pour venir au   <span className="oliver">spectacle</span></p>
						<Link
							to='https://cierenards.be/agenda/'
							target='_blank'
							className='cta_orangered'>
							Agenda
						</Link>
					</div>
					<div className='avantScpectacle'>
						<p>Ou bien vous pouvez consulter notre page <span className="oliver">« Avant le spectacle »</span></p>
						<Link to='/avant-le-spectacle' className='cta_orangered'>
							Avant le spectacle
						</Link>
					</div>
				</div>

				{/* <p>Revenez après avoir vu le spectacle !</p> */}
			</div>
		</div>
	);
}
