import React from "react";
import { Link } from "react-router-dom";
import "./ModalFoxesChouette.scss";

export default function ModalTadamChouette({ toggleModal }) {
	return (
		<div id='modal-foxes-chouette' className='modal__wrapper modal__wrapper--blue'>
			<h5>Bonus Tadam</h5>
			<h4>Chouette</h4>
			<p>
				On a rassemblé ici des{" "}
				<span className='oliver'> questions, des réflexions et des petits jeux </span> à propos
				du spectacle … Maintenant <span className='oliver'>cliquez !</span>
			</p>

			<Link to='/foxes' className='cta_orangered'>
				À vous de jouer !
			</Link>
		</div>
	);
}
